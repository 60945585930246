import * as React from "react"
import Layout from "../components/layout2023"
import Team from "../components/process/process"
import SeoNoIndex from "../components/seo-no-index-ablyft"

const FullServiceTeam = () => (
  <Layout nav={"hidden"}>
    <SeoNoIndex title="Process" index={false} follow={false} />
    <Team wrapperClass={"process"} />
  </Layout>
)

export default FullServiceTeam
