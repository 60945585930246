import React, { lazy, Suspense } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./process-form"
import FallbackLoading from "../loader/loader";
import "./process.scss"
const SliderSection = lazy(() => import('./slider'))

export default function Process(props) {
  const isSSR = typeof window === "undefined"
  return (
    <span className={props.wrapperClass}>
      <section className={"hero-section"}>
        <SiteNavigation />
        <div className="hero-content-wrap">
          <div className="hero-left">
            <div className="hero-superheading">PROCESS</div>
            <div className="hero-heading">Get more wins with a full pipeline of evidence-based tests. <br />Go live in 2 weeks.</div>
            <div className="hero-subheading">
              <p>Start fast with your first test live in just two weeks. Get a full pipeline of tests in three months. Then keep iterating and scaling to create predictable ongoing revenue growth.</p>
              <p>By launching more tests and better evidence-based tests our clients average a 34% lift in their first 90 days.</p>
            </div>
            <div className="page-anchors"><Link to="/process#quickstart">Quickstart</Link> · <Link to="/process#pipeline">Building a Pipeline</Link> · <Link to="/process#iteration">Iterating</Link></div>
            <Link className="arrow-button" to="/get-demo/">Get a Demo --></Link>
          </div>
          <div className="hero-right">
            <Image
              sourceFolder={'process'}
              lazyLoad={"false"}
              alt={"hero image"}
              fallBackImage={'process-hero-desktop.webp'}
              imgName={[
                {
                  imageName: "process-hero-desktop.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "process-hero-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
      </section>
      <section className="social-proof-section">
        <Image
          sourceFolder={'homepage'}
          lazyLoad={"true"}
          alt={"Social proof"}
          fallBackImage={'2023-social-logos-mobile.webp'}
          imgName={[
            {
              imageName: "2023-social-logos-desktop.webp",
              minBreakpoint: "992px",
            },
            {
              imageName: "2023-social-logos-tablet.webp",
              minBreakpoint: "768px",
            },
            {
              imageName: "2023-social-logos-mobile.webp",
              minBreakpoint: "320px",
            },
          ]}
        />
      </section>
      <section className="how-it-works" id="quickstart">
        <div className="row-wrap">
          <div className="row-left">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"Quick Start"}
              fallBackImage={'quick-start-desktop.webp'}
              imgName={[
                {
                  imageName: "quick-start-desktop.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "quickstart-tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "quickstart-mobile.webp",
                  minBreakpoint: "320px",
                }
              ]}
            />
            <div className="image-caption">Get your first three designs on day 1 of our engagement.  Your first test launched in week 2.</div>
          </div>
          <div className="row-right">
            <div className="row-super-heading">QUICKSTART (WEEKS 1 - 2)</div>
            <div className="row-heading"><span>Start fast.</span> Launch your first test<br/> in 2 weeks.</div>
            <div className="row-text">
              <p>Set the pace for the engagement by getting off to a running start. During our kickoff get mockups for your first 3 tests.  These initial tests are based on <span>proven repeat winners</span> from our <Link to="/prediction-engine/">prediction engine.</Link></p>
            </div>
          </div>
        </div>
      </section>
      <section className="pipeline-section" id="pipeline">
        <div className="pipeline-superheading">Building a PIPELINE (Months 1 - 3)</div>
        <div className="pipeline-heading"><span>An Overflowing Pipeline</span> of Tests<br/> in 3 Months</div>
        <div className="pipeline-subheading">Create a pipeline of built-out tests on all your most important pages. Eliminate downtime between tests. Here's everything that happens in those first three months.</div>
        <div className="pipeline-cards">
          <div className="card-item">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"conversion mapping"}
              imgName={"conversion-mapping.webp"}
            />
            <div className="card-heading">Conversion Mapping</div>
            <div className="card-description">Identify your best conversion opportunities. We map out the pages that contribute the most conversions or that assist conversions.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"research & analytics"}
              imgName={"research-analytics.webp"}
            />
            <div className="card-heading">Research & Analytics</div>
            <div className="card-description">Get a full research audit to uncover what is stopping visitors from converting. Interviews, heatmapping, session recording, objection polling, message testing, and more. Finding synthesized, ready to test.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"Design"}
              imgName={"design_n_n.png"}
            />
            <div className="card-heading">Design</div>
            <div className="card-description">Hypothesis from predictive analytics and our research audit is turned into high-fidelity mockups. On brand, in your voice. Tests your entire team will want to win.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"Development"}
              imgName={"development.webp"}
            />
            <div className="card-heading">Development</div>
            <div className="card-description">Tests are built-out in the testing tool, QAed, and ready for your final approval. When you give us the thumbs up, we launch the A/B test.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"Results"}
              imgName={"results.webp"}
            />
            <div className="card-heading">Results</div>
            <div className="card-description">Clients average 10 tests launched and a 34% lift in conversions in their first 3 months. Because we are using proven winners - you get fast predictable results.</div>
          </div>
          <div className="card-item">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"hard coding"}
              imgName={"hardcoding.webp"}
            />
            <div className="card-heading">Hard Coding</div>
            <div className="card-description">We even hard code your winners natively in your CMS - so they run faster and get you even better results. Or if you prefer get all the assets for your dev team.</div>
          </div>
        </div>
      </section>
      <Image
        sourceFolder={'process'}
        lazyLoad={"true"}
        alt={"Healthy Pipeline"}
        fallBackImage={'healthy-pipeline.webp'}
        className={"section-as-image"}
        imgName={[
          {
            imageName: "healthy-pipeline.webp",
            minBreakpoint: "992px",
          },
          {
            imageName: "healthy-pipeline-tablet.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "healthy-pipeline-mobile.webp",
            minBreakpoint: "320px",
          },
        ]}
      />
      <section className="how-it-works" id="iteration">
        <div className="row-wrap">
          <div className="row-left">
            <Image
              sourceFolder={'process'}
              lazyLoad={"true"}
              alt={"Iterate and scale"}
              fallBackImage={'iterate-scale.webp'}
              imgName={[
                {
                  imageName: "iterate-scale.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "iterate-tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "iterate-scale-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="image-caption">Insights from your wins are scaled across your site to unlock the full value of your learnings.</div>
          </div>
          <div className="row-right">
            <div className="row-super-heading">ITERATE (MONTH 3+)</div>
            <div className="row-heading"><span>Iterate and Scale</span> to<br /> Drive Ongoing Revenue Growth</div>
            <div className="row-text">
              <p>Iterate using testing results, research, and the latest trends from our prediction engine to find new wins. Scale insights from those wins to all your pages to magnify the results.</p>
              <p>Create a process for generating a predictable stream of ongoing revenue growth.</p>
            </div>
          </div>
        </div>
      </section>
      <FormSection />
      {
        !isSSR && (
          <Suspense fallback={<FallbackLoading />}>
            <SliderSection />
          </Suspense>
        )
      }
    </span >
  )
}
